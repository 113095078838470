import { useEffect, useState } from "react"
import { Theme, createStyles, makeStyles, ThemeProvider } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography"
import AppTheme from "./AppTheme"
import List from "@material-ui/core/List"
import ListItemText from "@material-ui/core/ListItemText"
import ITALogo from "./assets/images/ita-logo.png"

import moment from 'moment';

const { getJsDateFromExcel } = require("excel-date-to-js");
const queryString = require("query-string");

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mention: {
            maxWidth: 700,
            width: "80%",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: 60,
            [theme.breakpoints.down("sm")]: {
                margin: "30px 10px",
                padding: "0 10px",
                width: "auto",
            },
        },
        card: {
            display: "flex",
            borderRadius: 20,
            boxShadow: "0px 20px 40px -20px #00000025, 0px -5px 25px 0px #00000008",
            minWidth: "300px",
            maxWidth: "800px",
            justifyContent: "space-between",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column-reverse",
                alignItems: "center",
                margin: "0",
                width: "95%",
                boxShadow: "0px 20px 40px -20px #00000025, 0px -5px 25px 0px #00000000"
            }
        },
        cover: {
            height: 335,
            width: 255,
            flex: "1 0 auto",
            [theme.breakpoints.down("xs")]: {
                borderRadius: 15,
                margin: 0,
                height: 250,
                width: 190
            }
        },
        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            flex: "1 0 auto",
            padding: "30px 50px",
            maxHeight: 275,
            [theme.breakpoints.down("sm")]: {
                padding: "30px 50px",
                "&:last-child": {
                    paddingBottom: "30px"
                },
            },
            [theme.breakpoints.down("xs")]: {
                padding: "20px 25px",
                "&:last-child": {
                    paddingBottom: "30px"
                },
            }
        },
        logoWrapper: {
            display: "flex",
            minWidth: 475,
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: 80,
            [theme.breakpoints.down("sm")]: {
                margin: '50px 0',
                justifyContent: "space-evenly",
                minWidth: 'auto',
                maxWidth: 750,
                width: '100%'
            },
            [theme.breakpoints.down("xs")]: {
                margin: "75px 0 60px",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: 'center'
            }
        },
        logo: {
            height: 70,
            width: "auto",
            [theme.breakpoints.down("sm")]: {
                height: 75,
            },
            [theme.breakpoints.down("xs")]: {
                height: 60,
                marginBottom: 40
            }
        },
        logo2: {
            height: 90,
            width: "auto",
            [theme.breakpoints.down("xs")]: {
                height: 75,
            }
        },
        details: {
            display: "flex",
            flexDirection: "column",
        },
        item: {
            margin: "10px 0",
            "&:first-child": {
                marginTop: "0"
            },
            "&:last-child": {
                marginBottom: "0"
            },
            [theme.breakpoints.down("xs")]: {
                margin: "10px 0",
            }
        },
        name: {
            color: "#00A9E6"
        },
        controls: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        playIcon: {
            height: 38,
            width: 38,
        },
    })
);

export interface DetailsResponse {
    IDCOId: string
    IDCOName: string
    IDCOCode: string
    IDCOOrganization: string
    IDCOExpiration: string
    LinkFilename: string
    IDCOType: string
}

export default function IDCOCArd() {
    const classes = useStyles()

    const [name, setName] = useState("")
    const [id, setId] = useState("")
    const [code, setCode] = useState("")
    const [organization, setOrganization] = useState("")
    const [paramId, setParamId] = useState("")
    const [expiration, setExpiration] = useState("")
    const [fileName, setFileName] = useState("");
    const [recordType, setRecordType] = useState("default");

    // eslint-disable-next-line
    useEffect(() => {
        const parsed = queryString.parse(window.location.search)

        if (parsed.id) {
            setParamId(parsed.id)
        }
    }, [])

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [paramId])

    function fetchData() {
        console.log()

        let url = "https://ita-idco.azurewebsites.net/api/GetIDCODetails?code=02hCdgnezgj4scriJqBvS75etgUWrHcfeD/l7mPjuJF3A2z4aUK1Hw==";

        if (paramId !== "") {
            fetch(url + "&id=" + paramId).then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json().then((r: DetailsResponse) => {

                    debugger;

                    const test = getJsDateFromExcel(r.IDCOExpiration);

                    console.log(test);

                    debugger;

                    setName(r.IDCOName)
                    setId(r.IDCOId)
                    setCode(r.IDCOCode)
                    setOrganization(r.IDCOOrganization)
                    setExpiration(r.IDCOExpiration)
                    setFileName(r.LinkFilename)
                    if (r.IDCOType) {
                        setRecordType(r.IDCOType)
                    }
                    else {
                        setRecordType("IDCO")
                    }

                })
            })
        }
    }
    return (
        <ThemeProvider theme={AppTheme}>

            {recordType === "IDCO" &&
                <Typography className={classes.mention}>The International Testing Agency (ITA) confirms that this person has completed the ITA International Doping Control Officer Training Program and is certified by the ITA. The certification is valid until the expiration date indicated below. This certificate does not replace or serve as the accreditation or identification card issued by the DCO's respective organization.</Typography>
            }
            {recordType === "ITA" &&
                <Typography className={classes.mention}>The International Testing Agency (ITA) confirms that this person is currently registered as an International Testing Agency Officer.</Typography>
            }
            {recordType === "BCO" &&
                <Typography className={classes.mention}>The International Testing Agency (ITA) confirms that this person is an International Blood Collection Officer contracted by the ITA. The cardholder is authorized to conduct testing missions under the authority of the ITA on behalf of ITA’s partners, in full compliance with the World Anti-Doping Code and WADA’s International Standards. This card remains valid until the expiration date specified below. </Typography>
            }
            {recordType === "ITAIDCO" &&
                <Typography className={classes.mention}>The International Testing Agency (ITA) confirms that this person is an International Doping Control Officer contracted by the ITA. The cardholder is authorized to conduct testing missions under the authority of the ITA on behalf of ITA’s partners, in full compliance with the World Anti-Doping Code and WADA’s International Standards. This card remains valid until the expiration date specified below.</Typography>
            }
            {
                recordType === "DCOBCO" &&
                <Typography className={classes.mention}>The International Testing Agency (ITA) confirms that this person is an International Doping Control Officer and International Blood Collection Officer contracted by the ITA. The cardholder is authorized to conduct testing missions under the authority of the ITA on behalf of ITA’s partners, in full compliance with the World Anti-Doping Code and WADA’s International Standards. This card remains valid until the expiration date specified below.</Typography>
            }
            {
                recordType === "IBCO" &&
                <Typography className={classes.mention}>The International Testing Agency (ITA) confirms that this person has completed the ITA International Blood Collection Officer Training Program and is certified by the ITA. The certification is valid until the expiration date indicated below. This certificate does not replace or serve as the accreditation or identification card issued by the BCO's respective organization.</Typography>
            }
            {
                recordType === "TRAINEE" &&
                <Typography>The International Testing Agency (ITA) confirms that this person is an International Doping Control Officer Trainee contracted by the ITA. The cardholder is authorized to conduct testing missions under the authority of the ITA on behalf of ITA’s partners, in full compliance with the World Anti-Doping Code and WADA’s International Standards. This card remains valid until the expiration date specified below.</Typography>
            }

            <Card className={classes.card}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography variant="h4" className={classes.name}>
                            {name}
                        </Typography>
                        <List>
                            <ListItemText className={classes.item}>{organization}</ListItemText>
                            <ListItemText className={classes.item}>Card ID: {id}</ListItemText>
                            <ListItemText className={classes.item}>{recordType === "IDCO" ? "IDCO" : "ID Number"}: {code}</ListItemText>
                            <ListItemText className={classes.item}>Valid until: {expiration ? moment(getJsDateFromExcel(expiration)).format("DD/MM/YYYY") : ""}</ListItemText>
                        </List>
                    </CardContent>
                </div>
                {fileName !== "" && <CardMedia
                    className={classes.cover}
                    image={
                        "https://ita-idco.azurewebsites.net/api/GetIDCOImage?code=cpclULdsy3bXrskeLRbn2WbXlwFXHCY4jeRTB3nWHcZ80hB9ObvdKQ==&id=" +
                        fileName
                    }
                    title="IDCO Personal Picture"
                />}
            </Card>

            <div style={{ paddingTop: "2rem" }}>
                <a href="https://ita.sport/" target="_blank" rel="noreferrer"><img src={ITALogo} className={classes.logo} alt="The ITA logo" /></a>
            </div>

        </ThemeProvider >
    );

}
